import React from 'react'
import { Link } from 'react-router-dom'
const EnglishLanguageLab = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Facilities</li>
                 <li>English Language Lab</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>English Language Lab  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <p>Another addition by way of technology facilitating education is an English Language Laboratory. This is a set up where students improve their spoken language by listening to correct pronunciation, practice it and check their progress in a personalized manner. The Language Lab has a number of P.Cs with ear phones and speakers for each PCs. Students listen to the audio content but speak themselves while a software forms the platform for guiding each one to better his/ her speaking skill and improving upon it with the correct pronunciation, accent and diction.</p>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default EnglishLanguageLab

