import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {getNews} from'../Service/Api'
import Slider from "react-slick"; 
import CustomNextArrow from '../Pages/CustomNextArrow';
import CustomPrevArrow from '../Pages/CustomPrevArrow';
const News = () => {
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(true);
  const [error,setError] = useState(null);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

   useEffect(() =>{
         const fetcData = async () =>{
                 try{
                      const newsData = await getNews();
                      setData(newsData);
                      console.log(newsData);
                 }catch(error){
                     setError(error);
                 }finally{
                     setLoading(false);
                 }
         }
         fetcData();
   },[])
   const emptyData = [
     {title:"Stay Tunned For More Updates",description:"Stay Tunned For More Updates" ,date:"DD,MM,YY"},
     {title:"Stay Tunned For More Updates",description:"Stay Tunned For More Updates" ,date:"DD,MM,YY"},
     {title:"Stay Tunned For More Updates",description:"Stay Tunned For More Updates" ,date:"DD,MM,YY"},
     {title:"Stay Tunned For More Updates",description:"Stay Tunned For More Updates" ,date:"DD,MM,YY"}

 ]
 const settings = { 
  autoplay: true,
  infinite: false,
  autoplaySpeed: 2500,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
  responsive: [
    { breakpoint: 1200, settings: { slidesToShow: 1 } },
    { breakpoint: 992, settings: { slidesToShow: 2 } },
    { breakpoint: 767, settings: { slidesToShow: 1 } },
    { breakpoint: 480, settings: { slidesToShow: 1 } }
  ]
};
  return (
     <>
     <Slider {...settings}>
     { data && data.length > 0 ? (data.map((item,index) =>  {
          const date = new Date(item.fromDate); 
          const mon = monthNames[date.getMonth()];
          const eventdate = date.getDate();
          const year = date.getFullYear();
              return(
                <div className="item" key={index}>
                      <Link to={`https://webapi.entab.info/api/image/${item.attachments[0]}`} target="_blank" className="news-blk"> 
                          <div className="content">
                          <div className="date">
                                <h3>{eventdate}, {mon} {year}</h3>
                            </div>
                              <h3>{item.title}</h3>
                              <p>{item.description}</p>
                          </div>
                      </Link>
              </div> 

              )
     })): (emptyData.map((item,index) => ( 
    <div className="item" key={index}>
             <Link to="#" target="_blank" className="news-blk"> 
                 <div className="content">
                 <div className="date">
                      <h3>{item.date} </h3>
                  </div>
                     <h3>{item.title}</h3>
                     <p>{item.description}</p>
                 </div>
             </Link>
     </div>
    
   ))) }
         </Slider>
     </>
  )
}

export default News