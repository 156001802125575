import React from 'react'
import { Link } from 'react-router-dom'
const PromotionPolicy = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Rules</li>
                 <li>Promotion Policy</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Promotion Policy  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <p>Two semester examinations and two Unit Tests are held in a year. In order to pass in an examination a student shall secure a minimum of 40% marks in each subject.</p>
                    <p>Grades and not ranks are awarded to the children after each examination in order to encourage the weaker ones also to try and perform better in the future exams. No grade will be given if a child has failed or absented in any subject. The percentage of total marks one should obtain for grades is as follows.</p>
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr className="table-head">
                          <td width="12%"><b>S.No.</b></td>
                          <td width="22%"><b>Grade</b></td>
                          <td width="22%"><b>Percentage</b></td>
                          <td width="22%"><b>Category</b></td>
                          <td width="22%"><b>Points</b></td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>A+ </td>
                          <td>90-100 </td>
                          <td>Distinction </td>
                          <td>1 </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>A </td>
                          <td>80-89</td>
                          <td>Excellent </td>
                          <td>2</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>B</td>
                          <td>70-79</td>
                          <td>Very Good </td>
                          <td>3 </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>C</td>
                          <td>60-69 </td>
                          <td>Good</td>
                          <td>4 </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>D</td>
                          <td>50-59 </td>
                          <td>Satisfactory</td>
                          <td>4 </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>E</td>
                          <td>40-49 </td>
                          <td>Average </td>
                          <td>4 </td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>F</td>
                          <td>Below 40</td>
                          <td>Fail </td>
                          <td>7</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <p>The grading is a subjective evaluation. Hence besides considering the percentage of marks, the other areas of evaluation and internal assessment are as follows: attendance, obedience, neatness, politeness, punctually, sociability and congeniality.</p>
                    <p>Promotions are scrutinised on the basis of one year’s work and not merely on marks scored in the final examination. Every student must be present for the unit tests and semester examinations.</p>
                    <p>Each students is required to be present for a minimum 85% of school days as a pre-condition for promotion.</p>
                    <p>There shall be no Re-Test/Re-Examinations.</p>
                    <p>The result declared at the end of the year is final and cannot be reconsidered. In case of any dispute the decision of the Principal shall be final and binding on all concerned.</p>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default PromotionPolicy

