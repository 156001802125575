import React from 'react'
import { Link } from 'react-router-dom'
const GeneralRules = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Rules</li>
                 <li>General Rules</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>General Rules  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <ul className="mainlist">
                    <li>Students should arrive at the school at least 15 minutes before the commencement of the Morning Assembly.</li>
                    <li>Soon after the Assembly bell, the school-gate will be closed and the late corners will be asked to go home at the risk of the parents/guardians.</li>
                    <li>No one who has been absent or late may be allowed to attend the class without the Principal’s permission. The students who have been absent from the class, must have the reasons for their absence noted in the Regularity Record. Reasons of private nature may be intimated by letter. Birthdays, excursions, urgent business, etc., are not sufficient reasons.  No leave of absence is granted without prior application in writing from the parents/guardian. For marriage within the family only two days’ leave is granted, as a rule.</li>
                    <li>If any unauthorized absence exceeds fifteen calendar days, the absentee’s name will be struck off the rolls and would be considered as withdrawn.</li>
                    <li>The students of St. Francis School  should behave in  gentle and polite manner on their way to and from the school. They are reminded that the school is judged by their conduct.</li>
                    <li>Shouting, whistling and running about are not allowed in the school Building.</li>
                    <li>English shall be spoken at all times in the school premises by the students.</li>
                    <li>Students should take good care of their books and personal belongings. Each article should be marked with the pupil’s name, admission number and class.</li>
                    <li>The School is not responsible for goods lost. We discourage children from bringing any expensive articles to school.</li>
                    <li>Students are not allowed to wear ornaments in the School.</li>
                    <li>Students should bring to the school their text books and school diary. Magazines, Newspapers, etc., are not to be brought to the school.</li>
                    <li>No child will be sent home even if some urgent need arises, unless a duly authorized person comes to fetch him/her with a note of authorization from the parents along with the parents’ pass.</li>
                    <li>All children are expected to bring their Tiffin with them for the noon recess. (They are not permitted to buy eatables from vendors). Only plastic Tiffin boxes are permitted.</li>
                    <li>Students or their parents may not offer presents to the teachers except in those circumstances permitted by the Principal.</li>
                    <li>Any damage done to School Property should be made good by the pupil concerned.</li>
                    <li>Parents and guardians are not allowed to see their children or to visit the teachers or enter the school verandah during school hours, except with an explicit permission.</li>
                    <li>Attendants who bring children to the school must come reasonably dressed and leave the school immediately.</li>
                    <li>All home works should be done neatly and duly marking the date of the work. </li>
                    <li>All students are expected to take an active part in all the co-curricular activities.</li>
                    <li>No one is permitted to leave the School premises during the School hours or the lunch break without the written permission of the principal or the Vice-principal.</li>
                    <li>Mobile Phones, Cameras etc., are banned in school on all occasions. If any such forbidden gadgets are confiscated from a student, it will not be returned.</li>
                    <li>Electronic equipments, radios, C.D’s, pen drives, etc., are also not to be brought to school.</li>
                    <li>Students are not allowed to wear watch.</li>
                    <li>Eating gutka, pan, chewing gum and smoking or using  intoxicants of any sort are strictly prohibited  in the school.</li>
                    <li>Indulging in physical assault, vandalism, gang formation, making threats, using abusive language, spreading rumours etc. are strictly under disciplinary surveillance.</li>
                    <li>Keeping long nails and using nail polish are not permitted.</li>
                    <li>Girls can wear ear-rings of simple design either in black or white color only.</li>
                  </ul>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default GeneralRules

