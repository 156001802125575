import React from 'react'
import { Link } from 'react-router-dom'
const ComingSoon = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>  
                 <li>ComingSoon</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Coming Soon  </h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12">
                           <img src="https://webapi.entab.info/api/image/STFSR/public/Images/comingsoon.jpg" className="img-fluid cmimg" alt=" St. Francis School  Harmu, Ranchi " />
                      </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default ComingSoon

