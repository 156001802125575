import React from 'react'
import { Link } from 'react-router-dom'
const CoCirricular = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Facilities</li>
                 <li>Co-Cirricular</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Co-Cirricular  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <p>The school provides a variety co-curricular activities. There are classes held for Dance, Music, Karate, Yoga, Cooking, Dramatics, etc. There is a class devoted to developing skills in a number of literary cultural pursuits. All the students have to join a club of their choice where each one can get input from instructors in their area of interest. Some of the clubs are Science Club, Quiz Club, Elocution Club, Eco Club, Drama Club, etc.</p>
                    <p>There are also a number of outdoor activities, sports and games and social work.</p>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default CoCirricular

