import React from 'react'
import { Link } from 'react-router-dom'
const Uniform = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Academic</li>
                 <li>Uniform</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Uniform  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <h5>Summer Uniform for Junior Section (Std. Nursery - IV)</h5>
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr className="table-head">
                          <td width="50%"><b>Boys</b></td>
                          <td width="50%"><b>Girls</b> </td>
                        </tr>
                        <tr>
                          <td><strong>Shirts:</strong> Off-white cotton half shirts (D. W. M. Lal Premium Mills) with matching collar and school monogram to be printed on the pocket. </td>
                          <td><strong>Shirts:</strong> Off-white cotton half shirts (D. W. M. Lal Premium Mills) with matching collar and school monogram to be printed on the pocket. </td>
                        </tr>
                        <tr>
                          <td><strong>Pants:</strong>  Elastic half pants of Denim Milange colour - D. W. Premium Mills.</td>
                          <td><strong>Skirt:</strong>  "Denim Milange" color Ureb/Umbrella Cut skits.</td>
                        </tr>
                        <tr>
                          <td><strong>Socks:</strong>  Off white cotton socks with Denim Milange Colour border.</td>
                          <td><strong>Socks:</strong>  Off white cotton socks with Denim Milange Colour border. Off white ribbon. Belt available at School.</td>
                        </tr>
                        <tr>
                          <td><strong>Shoes:</strong>  Black school uniform leather shoe. Belt available at school.</td>
                          <td>-</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="clr10"></div>
                    <h5>Summer Uniform for Senior Section (Std. V-X)</h5>
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr className="table-head">
                          <td width="50%"><b>Boys</b></td>
                          <td width="50%"><b>Girls</b> </td>
                        </tr>
                        <tr>
                          <td><strong>Shirts:</strong>  Off-white cotton half shirts (D. W. M. Lal Premium Mills) with matching collar and school monogram to be printed on the pocket.</td>
                          <td><strong>Shirts:</strong>  Off-white cotton half shirts (D. W. M. Lal Premium Mills) with matching collar and school monogram to be printed on the pocket. </td>
                        </tr>
                        <tr>
                          <td><strong>Pants:</strong>  Formal full pants of  Denim Milange colour - D. W. Premium Mills.</td>
                          <td><strong>Skirt:</strong> "Denim Milange" color Ureb/Umbrella Cut skirts.</td>
                        </tr>
                        <tr>
                          <td><strong>Socks:</strong> Off white cotton socks with Denim Milange Colour border.</td>
                          <td><strong>Socks:</strong>  Off white Knee length cotton socks with Denim Milange Colour border.</td>
                        </tr>
                        <tr>
                          <td><strong>Shoes:</strong> Black school uniform leather shoes <br/>
                              <strong>Tie: </strong> Designer School Tie and belt available at school. </td>
                          <td><strong>Tie:</strong>  Designer School Tie and belt available at School. Off white ribbon.</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="clr10"></div>
                    <h5>Winter Uniform</h5>
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr className="table-head">
                          <td width="25%"><b>Junior Boys (Class Nursery -V)</b></td>
                          <td width="25%"><b>Senior Boys (Class VI - X)</b> </td>
                          <td width="25%"><b>Junior Girls (Class Nursery -V)</b></td>
                          <td width="25%"><b>Senior Girls (Class VI-X)</b> </td>
                        </tr>
                        <tr>
                          <td> Designer V necked pullover. </td>
                          <td>Designer Blazer.</td>
                          <td>Designer V necked pullover Front open</td>
                          <td>Designer Blazer</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="clr10"></div>
                    <h5>P. T. Dress</h5>
                    <p><strong>On Thursdays however, all students shall wear P. T. Uniforms. (During Summer Thursdays they shall wear their respective pullovers/ blazers also).</strong></p>
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr className="table-head">
                          <td width="50%"><b>Boys</b></td>
                          <td width="50%"><b>Girls</b> </td>
                        </tr>
                        <tr>
                          <td>Designed half shirt, white shorts or trousers, white canvas shoes, white socks.</td>
                          <td>White knee level pleated skirt, designed half shirts, white ribbon/white hair band, white canvas shoes and white knee length socks. </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="clr10"></div>
                    <h5>Hair Style for Girls</h5>
                    <p><strong>On Thursdays however, all students shall wear P. T. Uniforms. (During Summer Thursdays they shall wear their respective pullovers/ blazers also).</strong></p>
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr className="table-head">
                          <td width="100%"><b>Girls of Class IV-X </b></td>
                        </tr>
                        <tr>
                          <td className="text-center">Should tie their hair in two braids/plaits. Any exception to this will be allowed only with the written permission of the Principal. </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Uniform

