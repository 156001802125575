import React from 'react'
import { Link } from 'react-router-dom'
const Curriculum = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>Curriculum</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Curriculum  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <p >The medium of instruction is English, but very special emphasis is gives to Hindi, the national language. The complete curriculum consists of English, Hindi, Sanskrit, History, Civics, Geography, Economics, Mathematics, Physics, Chemistry and Biology. Lectures on character formation and development of spiritual values are imparted regularly to students. The main aim of the School is not only to impart instructions but in a spirit of love and service we strive to formulate their intellectual, moral, spiritual, emotional, physical, social and aesthetic faculties as well. The School also encourages students to discover and promote inherent potentialities through promoting sports, games, cultural activities, hobbies etc.</p>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Curriculum

