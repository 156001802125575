import React from 'react'
const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: `url(https://webapi.entab.info/api/image/STFSR/public/Images/next-arrow.png) no-repeat center center`, backgroundSize: 'contain' }}
        onClick={onClick}
      />
    );
  }

export default CustomNextArrow
