import React from 'react'
import { Link } from 'react-router-dom'
const Faculty = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Academic</li>
                 <li>Faculty</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Faculty  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr className="table-head">
                          <td width="5%"><b>S.No.</b></td> 
                          <td width="25%"><b>Employee Name</b></td>
                          <td width="25%"><b>Designation</b></td>
                          <td width="20%"><b>Qualificatiom</b></td> 
                       </tr>
                          <tr><td>1</td> <td>Fr. Shaji</td><td>Principal</td><td>Teacher</td></tr>
                          <tr><td>2</td><td>Fr. Sumit</td><td>Vice-Principal</td><td>Teacher</td></tr>
                          <tr><td>3</td><td>Fr. Jasman</td><td>Vice-Principal</td><td>Teacher</td></tr>
                          <tr><td>4</td><td>Sr. Sharon CHF</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>5</td><td>Mrs. Rekha Bakshi</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>6</td><td>Mr. Ashok Kumar</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>7</td><td>Mrs. Binu Biju</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>8</td><td>Mr. Santosh Kumar</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>9</td><td>Mrs. Swati Singh</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>10</td><td>Mrs. Nirja Jha</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>11</td><td>Mr. Biju Mavara</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>12</td><td>Mrs. Rashmi Srivastava</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>13</td><td>Mrs. Huma</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>14</td><td>Mrs. Aayushi Divya</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>15</td><td>Mrs. Farhat Noamani</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>16</td><td>Mrs. Maduparna</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>17</td><td>Ms. Sonali Barla</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>18</td><td>Mrs. Alaknanda</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>19</td><td>Ms. Priyanka Parida</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>20</td><td>Ms. Prashansa</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>21</td><td>Ms. Mansi Xess</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>22</td><td>Ms. Mamta Kr. Mahto</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>23</td><td>Ms. Nirmala</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>24</td><td>Ms. Shail Priya</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>25</td><td>Mrs. Divya Verma</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>26</td><td>Mrs. Anita Soy</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>27</td><td>Mrs. Manisha Kapoor</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>28</td><td>Mr. Md. Maruf Habib</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>29</td><td>Mr. Sathish Kumar</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>30</td><td>Mrs. Mariamma</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>31</td><td>Mrs. Suman Mishra</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>32</td><td>Ms. Bahalen Topno</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>33</td><td>Mrs. Kumari Manju</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>34</td><td>Mr. Amarendra</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>35</td><td>Ms. Gloria</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>36</td><td>Mr. Ajay Shankar</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>37</td><td>Mrs. Usha Kumari</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>38</td><td>Mr. Anil Lakra</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>39</td><td>Sr. Angeline</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>40</td><td>Mr. Nilesh Patel</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>41</td><td>Mr. S. Sengupta</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>42</td><td>Mrs. Abhilasha</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>43</td><td>Mrs. Preeti Srivastava</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>44</td><td>Mrs. Nutan Mishra</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>45</td><td>Mrs. Sonalika M. Topno</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>46</td><td>Mrs. Swati C. Toppo</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>47</td><td>Mrs. Neha Kinger</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>48</td><td>Mrs. Anamika Sinha</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>49</td><td>Ms. Surbhi Hemrom</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>50</td><td>Mrs. Richa R. Kerketta</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>51</td><td>Mrs. Vijayalaxmi</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>52</td><td>Mrs. Sweta Khemka</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>53</td><td>Mrs. Sonali Das</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>54</td><td>Ms. Sujaya Demta</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>55</td><td>Mrs. Priti Kumari</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>56</td><td>Mrs. Bharati Dawson</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>57</td><td>Ms. Ruby Thakur</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>58</td><td>Mrs. Anamika sharan</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>59</td><td>Mrs. Saraswati</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>60</td><td>Mrs. Archana Gupta</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>61</td><td>Mrs. Mamta C.</td><td>Teacher</td><td>Teaching Staff</td></tr>
                          <tr><td>62</td><td>Mrs. Payal</td><td>Teacher</td><td>Teaching Staff</td></tr> 
                      </tbody>
                    </table>
                  </div>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Faculty

