import React from 'react'
import { Link } from 'react-router-dom'
const AdmissionProcedure = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Rules</li>
                 <li>Admission Procedure</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Admission Procedure  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <ul className="mainlist">
                    <li>  Boys and Girls are admitted to Nursery classes and they should have attained the age of  four or four and half years old as on the date of admissions. </li>
                    <li>  A valid birth certificate issued by an authorised person of the Panchayat or Municipality should be submitted at the time of admission to the Nursery Class.  </li>
                    <li>  Applications for admission to other classes are subject to availability of vacancy. In such cases the applicants will be asked to sit for an admission test, both oral and written, on a date fixed by the School.  </li>
                    <li>  Parents are advised to fill in the admission forms which contains names, date of birth etc., with utmost care and accuracy. No subsequent changes will be permitted thereafter for any reason whatsoever. </li>
                    <li>  A transfer certificate is required to be submitted from the School last attended by those students who take admissions in midway classes. </li>
                    <li>  However in case of students from other States seeking admission in the School must submit their transfer certificates  countersigned by proper authorities irrespective of the class into which admission is sought. </li>
                    <li>  A new child  must be introduced personally by the parents themselves to the Principal who will be responsible to the school for their conduct and payment of fees etc. </li>
                    <li>  Irrespective of the time of admission, fee will be charged for the whole academic year. With regard to admissions the decision of the principal shall be final and binding on all concerned. </li>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default AdmissionProcedure

