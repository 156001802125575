import React from 'react'
import { Link } from 'react-router-dom'
const LibraryRules = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Rules</li>
                 <li>Library Rules</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Library Rules  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <p>The Library building of the School is very spacious with adequate storage of books and sufficient furnitures for peaceful reading and brief writing. All important periodicals, magazines, dictionaries, guides etc., which are useful to the children are subscribed and facilitated. All students of Std. VI to X may borrow books from the library. One book at a time to each student is issued for a period of maximum 15 days. Any damage or loss of books should be made good by the student concerned. Delay in return of books would be fined by the days of delay at the discretion of the Principal.</p>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default LibraryRules

