import React from 'react'
import { Link } from 'react-router-dom'
const SchoolFee = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Rules</li>
                 <li>School Fee</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>School Fee  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <ul className="mainlist">
                    <li> St. Francis School receives no Government or Private aid and fees realized from students is the only source of revenue. To provide for  the rising costs and increase in salary, administrative costs etc., the hike in fees will be adjusted from time to time accordingly. In other words, the School reserve it’s right to revise the fees without prior notice. </li>  
                    <li> Tuition Fees are to be paid for all 12 months of the year. All fees must be paid by the end of the month for which they are due and may be paid in monthly installments or in advance. </li> 
                    <li> Pupils whose fees are long overdue may be barred from sitting for any examination and are liable to have their names struck off the rolls.  </li> 
                    <li> Fees are collected at the local bank specified by the school. Kindly see the instructions in Fee Book. </li>  
                    <li> All fees must be paid by the end of every month, failing which a late fine will be levied from the  defaulters. </li> 
                    <li> Parents may kindly save the receipt issued to them in proof of payment if needed. </li>  
                    <li> AFees for February and March shall be paid in February. </li> 
                    <li> Any failure in the regular payment of the school fees should be informed to the office in writing. </li>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default SchoolFee

