import React from 'react'
import { Link } from 'react-router-dom'
const Discipline = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>Discipline</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Discipline  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                         <p>The Development of character in education can only be attained through discipline. Every student is expected to strictly observe discipline in punctuality, neatness in dress and personal appearance, behavior, respect for elders, consideration for poor and needy and adherence to cooperative spirit etc. Repeated misbehavior, irregular attendance, insubordination, bad conduct, demonstrable lack of interest, behaviour detrimental to the discipline and moral tone of the School, repeated failures in Examinations etc. are sufficient reasons, singularly or collectively, for expulsion from School. Parents and guardians are requested to cooperate in this regard with the authorities of the school by ensuring the regular attendance and punctuality of their wards. Periodical reports will keep the parents informed of the progress of their wards. These reports are to be signed by the parents and returned. Students with sickness which are contagious or injurious to their schoolmates could also be suspended or removed from the School. Besides, various forms of corrective measures including fines may be imposed to discipline the erring students.</p>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Discipline

