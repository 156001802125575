import React from 'react'
import { Link } from 'react-router-dom'
const Career = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                 <li><Link to="/"> Home</Link> </li>  
                 <li>Career</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Career  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <p>Career planning and its build up is an important factor in the development and success of every person. In fact it refers to an individual’s journey through learning, work and other aspects of life. The School takes special care to infuse ambition to achieve targets and build up their career aiming high in the respective fields of their natural interests. Experts of career guidance are often invited to deliver lectures and children are encouraged to put up questions to clarify things further in-depth. Side by side the School also promotes leadership qualities and encourages them to lead for the good of majority and not for personal gratification. They are taught to develop leaders while leading and advised of the need to stick their feet firmly on the ground while holding their heads in the clouds.</p>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Career

