import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/STFSR`);
    console.log(data)
    return data;
} 

export const getNews = async() => {
    const { data } = await axios.get(API_URL + `/api/news/STFSR`);
   
    return data;
} 

export const getNewsInner = async() => {
    const { data } = await axios.get(API_URL + `/api/news/All/STFSR`);
    console.log(data)
    return data;
}
export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/STFSR`);
    console.log(data)
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/STFSR`);
    console.log(data)
    return data;
}
 
export const getBirthdays = async() => {
    const { data } = await axios.get(API_URL + `/api/birthdays/STFSR`);
    console.log(data)
    return data;
}

export const getGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/STFSR`);
    return data;
} 
export const getVideo = async() => {
    const { data } = await axios.get(API_URL + `/api/video/all/STFSR`, getHeader());
    return data;
} 
export const getCircular = async() => {
    const { data } = await axios.get(API_URL + `/api/Circular/all/STFSR`, getHeader());
    return data;
} 
export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/All/STFSR`);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data)
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/STFSR`,getHeader());
    return data;
} 

export const getTopper = async() => {
    const { data } = await axios.get(API_URL + `/api/topper/STFSR`,getHeader());
    return data;
} 

export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/STFSR`,getHeader());
    return data;
} 
 

export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/PrincipalMessage/STFSR`, getHeader());
    return data;
} 

  
export const postTransferCertificate = async(name,admission) => {
    console.log(name,admission)

    const { data } = await axios.post(API_URL + `/api/tc/STFSR`,{name,admission},getHeader());
    return data;
}
