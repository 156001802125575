import Home from'./Pages/Home'; 
import './Assets/css/Style.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './Components/Header'; 
import Footer from'./Components/Footer'
import OurSchool from'./Pages/OurSchool'
import Objective from'./Pages/Objective'
import Vision from'./Pages/Vision'
import Discipline from'./Pages/Discipline'
import Curriculum from'./Pages/Curriculum'
import PrincipalMessage from'./Pages/PrincipalMessage'
import AdmissionProcedure from'./Pages/AdmissionProcedure'
import PromotionPolicy from'./Pages/PromotionPolicy'
import Attendance from'./Pages/Attendance'
import GeneralRules from'./Pages/GeneralRules'
import SchoolFee from'./Pages/SchoolFee'
import LibraryRules from'./Pages/LibraryRules'
import AcademicCalendar from'./Pages/AcademicCalendar'
import Tc from'./Pages/Tc'
import Uniform from'./Pages/Uniform'
import OurFacilities from'./Pages/OurFacilities'
import SmartClass from'./Pages/SmartClass'
import EnglishLanguageLab from'./Pages/EnglishLanguageLab'
import CoCirricular from'./Pages/CoCirricular'
import Career from'./Pages/Career'
import Contact from'./Pages/Contact'
import ErpSoftware from'./Pages/ErpSoftware'
import Faculty from'./Pages/Faculty'
import News from'./Pages/News'
import Events from'./Pages/Events'
import Gallery from'./Pages/Gallery'
import SubGallery from'./Pages/SubGallery'
import TopperXII from'./Pages/TopperXII'
import TopperX from'./Pages/TopperX'
import VideoGallery from'./Pages/VideoGallery'
import Circulars from'./Pages/Circulars'
import ComingSoon from'./Pages/ComingSoon'
import VicePrincipalMessage from'./Pages/VicePrincipalMessage'
function App() {
  return (
     <BrowserRouter>
      <Header/> 
      <Routes>
        <Route path="/" element={<Home/>} /> 
        <Route path="/OurSchool" element={<OurSchool/>} /> 
        <Route path="/Objective" element={<Objective/>} /> 
        <Route path="/Vision" element={<Vision/>} /> 
        <Route path="/Discipline" element={<Discipline/>} /> 
        <Route path="/Curriculum" element={<Curriculum/>} /> 
        <Route path="/PrincipalMessage" element={<PrincipalMessage/>} /> 
        <Route path="/AdmissionProcedure" element={<AdmissionProcedure/>} /> 
        <Route path="/PromotionPolicy" element={<PromotionPolicy/>} /> 
        <Route path="/Attendance" element={<Attendance/>} /> 
        <Route path="/GeneralRules" element={<GeneralRules/>} /> 
        <Route path="/SchoolFee" element={<SchoolFee/>} /> 
        <Route path="/LibraryRules" element={<LibraryRules/>} />
        <Route path="/AcademicCalendar" element={<AcademicCalendar/>} />
        <Route path="/Tc" element={<Tc/>} />
        <Route path="/Uniform" element={<Uniform/>} />
        <Route path="/OurFacilities" element={<OurFacilities/>} />
        <Route path="/SmartClass" element={<SmartClass/>} />
        <Route path="/EnglishLanguageLab" element={<EnglishLanguageLab/>} />
        <Route path="/CoCirricular" element={<CoCirricular/>} />
        <Route path="/Career" element={<Career/>} />
        <Route path="/Contact" element={<Contact/>} />
        <Route path="/ErpSoftware" element={<ErpSoftware/>} />
       <Route path="/Faculty" element={<Faculty/>} />
        <Route path="/News" element={<News/>} />
        <Route path="/Events" element={<Events/>} />
        <Route path="/Gallery" element={<Gallery/>} />
        <Route path="/SubGallery" element={<SubGallery/>} />
        <Route path="/TopperXII" element={<TopperXII/>} />
        <Route path="/TopperX" element={<TopperX/>} />
        <Route path="/VideoGallery" element={<VideoGallery/>} />
        <Route path="/Circulars" element={<Circulars/>} />
        <Route path="/ComingSoon" element={<ComingSoon/>} />
        <Route path="/VicePrincipalMessage" element={<VicePrincipalMessage/>}/>
      </Routes> 
      <Footer/>
     </BrowserRouter>
  );
}

export default App;
