import React from 'react'
import { Link } from 'react-router-dom'
const SmartClass = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Facilities</li>
                 <li>Smart Class</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Smart Class  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                         <p><strong>St. Francis School </strong>is one of the very few schools in Ranchi which has converted all its class rooms into Smart-classes. The school has entered into a contract with HCL Digischool, an outstanding service provider in the education sector. With the new arrangement every class room of the school is equipped with a P. C., Projector and an Interactive Smart-board which enables teaching in a more child-centred  way. The set up in each class is linked by a LAN to a server which is the storehouse of a vast educational content. The whole syllabi of classes Nursery to X is available in this content. Teachers use the Smart-boards for regular teaching, storing lessons and demonstrating the ready made content available in the server. With this facility learning has become a multi-sensory exercise and visual learners will find learning a lot easier and entertaining.</p>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default SmartClass

