import React from 'react'
import { Link } from 'react-router-dom'
const VicePrincipalMessage = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School </li>
                 <li>Vice Principal Message</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Vice Principal Message  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                     <div className="clearfix"> 
                        <img src="https://webapi.entab.info/api/image/STFSR/public/Images/viceprincipal.png" className="img-fluid col-md-4 float-md-end mb-3" alt=""/>
                        <h5>Message on Modern Education System</h5>
                        <p>Our present education system seems to be insufficient. For, it is based on the theoretical knowledge. The students of today play no active role in attainment of knowledge. Things are loaded on one’s mind which he/she cannot digest, he only crams and therefore they never become his own. That is why, Dr, Annie Basant has justly said, ‘our education system is like filling students head with lot of disjointed facts poured into the heads as into a basket; to be emptied out again in examination hall and empty basket carried out again into the world.’ This is the reason why a student who succeeds well in his school-college examination fails miserable in his examination of life.</p>
                        <p>The existing system of our education is predominantly academic and theoretical. The student is taught lessons by books, but not lessons from life. In other words, he/she is provided with knowledge but not with wisdom. For instance, he is obliged to learn the history of Greece of 200 years ago, but he knows nothing of what’s happening in his own country of society.</p>
                        <p>In view of foregoing defects and short-comings, our system of education calls for a radical change, practical knowledge, self-reading, information gathering and work-experience in our schools. The school life should be given more importance to bring about a complete and harmonious development of all factors of human personality. A personality that which makes a person effective in society. It is that which influences people in the society. Therefore, regarding child’s personal development in the school, school atmosphere and discipline affects personality of a child. And also teacher’s personality and character, peer students’ attitude and character all influence personality of a child’s development.</p>
                        <p>Therefore, same type of friendly atmosphere St. Francis School, Harmu is trying to create in the school campus so that there will be all round growth and development of students. They can be better human beings and responsible citizens of our country. The school management and all the teachers are continuously trying their level best to achieve the goal. For this great and constant effort I would like to thank and appreciate the principal and the staff. May this firm step lead this institute to achieve the greater heights of success.</p>
                       <p>Greetings and Best Wishes </p>
                        <p className="name"> Fr. Sumit Tirkey, TOR  <span>   (Vice Principal) </span> </p> 
                        </div>
                        <div className="borderbottom"></div>
                        <div className="clearfix"> 
                            <img src="https://webapi.entab.info/api/image/STFSR/public/Images/viceprincipal1.png" className="img-fluid col-md-4 float-md-end mb-3" alt=""/>
                            <h5>Dear Parents and Students</h5>
                            <p>Founded in 1978, St. Francis School, Harmu, Ranchi is one of the first schools to believe in the importance of educating boys and girls to fulfil their potential, giving them the skills and confidence to take their place with pride in a modernising and fast-moving world. That tradition remains at our core. </p>
                            <p>From its very beginning, and in advance of its time, St. Francis School, Harmu embraced a liberal ideology. We believe that true potential can only be unlocked when given the freedom to grow. Our rules are few and relationships are relaxed, yet respectful. Our curriculum is broad with plenty of opportunity for individual research, discussion and debate.  Thus, there is never a dull moment at St. Francis School!</p>
                            <p>Success here is not a collective measure. What matters to us is the happiness and achievements of each student according to her/his own lights and ambitions. Our students take ownership of their lives, to embrace opportunity and to grow in resilience and self-reliance.</p>
                            <p>Learning here at St. Francis is not just about passing exams. It is about a love of academic pursuits, inspiration and imagination. It is about the quest for ‘very great wisdom and understanding, and knowledge as vast as the sands of the seashore’.</p>
                            <p>Dear parents, we appreciate your trust you have in us that you have selected our school among all for enrolling your child for a better education. We extend our thankful wishes to you and promise that we would work harder for a better development of your child.”</p>
                            <p>My dear beloved students think different and go after the excellence; and do something that makes your name stand out in a crowed; the joy of life even in a small thing, be an observer and sort it out with your wisdom and talent. </p>
                            <p>Best Of Luck! </p>
                            <p className="name">Fr. Jasman Toppo  <span>   (Vice Principal) </span> </p> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default VicePrincipalMessage

