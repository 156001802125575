import React from 'react'
import { Link } from 'react-router-dom'
const Vision = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>Vision</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Vision  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                         <p><strong>TRUTH, LOVE AND PEACE’</strong> is the motto of St. Francis School. The school regards as its objective, “to spread the love of God in the hearts of all people, irrespective of caste, creed and social status, in the spirit of St. Francis of Assisi, by instilling in all, the values of human dignity, brotherhood, equality, Justice and peace.” In order to educate the “Whole Man” in them the school imparts to its pupils a sound moral, intellectual and physical education with a view to develop their talents and potentialities and prepare them to face the challenges of life. The School also inculcates in them the courage and initiative needed to take up their tasks and shoulder their responsibilities through the values of social service, self discipline, self-reliance, punctuality, cleanliness, loyalty, politeness, respect for the elders and realising the importance of maintaining good physical and mental health by way of active involvement in games, sports and cultural activities.</p>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Vision

