import React from 'react'
import { Link } from 'react-router-dom'
const Attendance = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Rules</li>
                 <li>Attendance</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Attendance  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <p>Students are expected to attend the classes on all School days. A minimum of at least 85% of attendance of School days is required to be eligible for appearance in the examinations held in the School. This condition is also applicable to students appearing for ICSE Examinations. Reasons of absence must be written in the school diary and signed by the parent/guardian.</p>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Attendance

