import React from 'react'
import { Link } from 'react-router-dom'
const OurSchool = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>Our School</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Our School  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                         <h5>Introduction About Our School</h5>
                         <p>St. Francis School, founded in 1978, is an English Medium Co-educational School established and conducted by T.O.R. FRANCISCAN SOCIETY, a registered Charitable Society, having its headquarters at Ranchi, Jharkhand. The School has classes from Std. <b>NURSERY to XII</b> and is affiliated to the Council for the Indian School Certificate Examination, New Staffed by dedicated and qualified Sisters, Fathers and Brothers, the T.O.R. Francisian Society runs several schools in India in which young people belonging to every creed, social class, community and linguistic group are educated through the medium of English and the regional languages. These institutions are part of Catholic Church effort to share in the Country’s Educational undertaking. The effort, while particularly responsible for the Christian community has always been at the service of the whole nation.</p>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default OurSchool

