import React from 'react'
import { Link } from 'react-router-dom'
const OurFacilities = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Facilities</li>
                 <li>Our Facilities</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Our Facilities  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <p>The school strive to provide international facilities to the Children enabling and equipping them to face the World with all the basic skills and competitive confidence. We are proud to have a beautiful campus with state of the art buildings, well equipped laboratories, a spacious computer room with adequate stations and internet connections, dance room, nursing unit, drinking water facilities, activity room, a well maintained canteen etc. All the Science Labs are provided with sufficient stocks of gadgets, chemicals, safety equipments and above all staffed with trained teachers and supporting staff. The overall environment of the School provide a high standards of hygiene and better preparedness for prevention, detection and resistance of possible infections.</p>
                        <p>The School has the enviable reputation of having and propping up a brilliant strength of dedicated and professional faculty chosen from Sisters, Fathers, Brothers and other teachers ever ready to cater to the individual attention of every student. We take special care to limit the number of students in a class to facilitate personal attention.</p>
                   </div>
                   <div className="col-lg-4 col-md-4">
                   <div className="same-box">
                      <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/STFSR/public/Images/faci_1.jpg"><img src="https://webapi.entab.info/api/image/STFSR/public/Images/faci_1.jpg" className=" img-fluid" alt=" St. Francis School  Harmu, Ranchi " /></a>
                   </div>
                 </div>
                 <div className="col-lg-4 col-md-4">
                   <div className="same-box">
                      <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/STFSR/public/Images/faci_2.jpg"><img src="https://webapi.entab.info/api/image/STFSR/public/Images/faci_2.jpg" className=" img-fluid" alt=" St. Francis School  Harmu, Ranchi " /></a>
                   </div>
                 </div>
                  <div className="col-lg-4 col-md-4">
                   <div className="same-box">
                      <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/STFSR/public/Images/faci_3.jpg"><img src="https://webapi.entab.info/api/image/STFSR/public/Images/faci_3.jpg" className=" img-fluid" alt=" St. Francis School  Harmu, Ranchi " /></a>
                   </div>
                 </div>
                  <div className="col-lg-4 col-md-4">
                   <div className="same-box">
                      <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/STFSR/public/Images/faci_4.jpg"><img src="https://webapi.entab.info/api/image/STFSR/public/Images/faci_4.jpg" className=" img-fluid" alt=" St. Francis School  Harmu, Ranchi " /></a>
                   </div>
                 </div>
                  <div className="col-lg-4 col-md-4">
                   <div className="same-box">
                      <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/STFSR/public/Images/faci_5.jpg"><img src="https://webapi.entab.info/api/image/STFSR/public/Images/faci_5.jpg" className=" img-fluid" alt=" St. Francis School  Harmu, Ranchi " /></a>
                   </div>
                 </div>
                  <div className="col-lg-4 col-md-4">
                   <div className="same-box">
                      <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/STFSR/public/Images/faci_6.jpg"><img src="https://webapi.entab.info/api/image/STFSR/public/Images/faci_6.jpg" className=" img-fluid" alt=" St. Francis School  Harmu, Ranchi " /></a>
                   </div>
                 </div>
                  <div className="col-lg-4 col-md-4">
                   <div className="same-box">
                      <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/STFSR/public/Images/faci_7.jpg"><img src="https://webapi.entab.info/api/image/STFSR/public/Images/faci_7.jpg" className=" img-fluid" alt=" St. Francis School  Harmu, Ranchi " /></a>
                   </div>
                 </div>
                  <div className="col-lg-4 col-md-4">
                   <div className="same-box">
                      <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/STFSR/public/Images/faci_8.jpg"><img src="https://webapi.entab.info/api/image/STFSR/public/Images/faci_8.jpg" className=" img-fluid" alt=" St. Francis School  Harmu, Ranchi " /></a>
                   </div>
                 </div>
                  <div className="col-lg-4 col-md-4">
                   <div className="same-box">
                      <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/STFSR/public/Images/faci_9.jpg"><img src="https://webapi.entab.info/api/image/STFSR/public/Images/faci_9.jpg" className=" img-fluid" alt=" St. Francis School  Harmu, Ranchi " /></a>
                   </div>
                 </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default OurFacilities

