import React, { useEffect, useState } from "react";
import { getTopper } from "../Service/Api"; 
import { Link } from 'react-router-dom'
const TopperX = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [yearRanges, setYearRanges] = useState([]);   
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
     const currentYear = new Date().getFullYear();
     const Year = `${currentYear-1}-${currentYear}`;
     setSelectedYear(Year);
     console.log("get current Year", Year);
  }, []);

  useEffect(() => {
     const fetchData = async () => {
        const galleryData1 = await getTopper();
        const galleryData = galleryData1.filter((item) => item.class === 'X'); 
        setData(galleryData);  

        // Extract unique years
        const uniqueYears = Array.from(
           new Set(galleryData.map((item) => item.sessionYear))
        );

        console.log("Unique Years:", uniqueYears); 
        setYearRanges(uniqueYears);
        setFilteredData(galleryData); // Initialize filteredData with all data
     };
     fetchData();
  }, []);

  useEffect(() => {
     // Filter data based on selected year and search query
     let filteredData = data;
     if (selectedYear !== "All") {
        filteredData = filteredData.filter((item) => item.sessionYear === selectedYear);
     }
     if (searchQuery) {
        filteredData = filteredData.filter((item) =>
           item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
     }

     // Sort data by division in descending order
     filteredData.sort((a, b) => parseFloat(b.division) - parseFloat(a.division));

     setFilteredData(filteredData);
  }, [selectedYear, searchQuery, data]);
  const emptyArray = [
   { name: "Student Name", division: "%",stream:"Stream" },
   { name: "Student Name", division: "%",stream:"Stream" },
   { name: "Student Name", division: "%",stream:"Stream" },
   { name: "Student Name", division: "%",stream:"Stream" }
 ];
  return (
     <>
     
        <div className="innerslide">
        <ul className="breadcrumb">
            <li><Link to="/"> Home</Link> </li>
            <li> Topper X  </li>
        </ul>
    </div>
    <div className="innersec">
    <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <h1>Topper X </h1>
                </div>
                <div className="col-lg-6">
                <div className="chooseyear">
                <select id="chooseddlYear"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    className="yearSelection"
                >
                    <option value={"All"}>All</option>
                    {yearRanges.map((yr) => (
                        <option key={yr} value={yr}>{yr}</option>
                    ))}
                </select>
   
            </div>
              </div>
            </div>
        <div className="row">
        {filterData && filterData.length > 0  ? (filterData.map((item, index) => (
               <div className="col-lg-3">
                <div className="topperdiv" key={index}>
                  <div className="topperimages">
                    <img src={`https://webapi.entab.info/api/image/${item.attachments}`} className="img-fluid"/>
                  </div>
                  
                  <div className="topperdesc">
                     <p className="per"> {item.division}</p>
                     <p className="name">{item.name}   </p>
                  </div>
               </div>
            </div>  
            ))):(emptyArray.map((item, index) => ((
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={index}>
              <div className="topperdiv">
                      <div className="topperimages">
                          <img src="https://webapi.entab.info/api/image/STFSR/public/Images/defaultImage.jpg" className="img-fluid"/>
                      </div>
                      
                      <div className="topperdesc">
                          <p className="per">{item.division}</p>
                          <p className="name"> {item.name}  </p>
                      </div>
                  </div>
           </div>
              ))))}  
          
        </div>
    </div>
    </div>
      
     </>
  )
}

export default TopperX
