import React from 'react'
import { Link } from 'react-router-dom'
const Objective = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>Objective</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Objective  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                         <p>What makes a man or woman benevolent or noble is not the colour of the skin or the race to which he / she belongs, not even the education but it’s the character, which is formed by the practice of certain virtues. We make it sure that it shall be the prerogative of every Franciscan Student to be kind, gentle, respectful, helpful, cheerful, hardworking and enthusiastic at all times. They will never indulge in anything which may displease God or hurt their fellow human beings. Firmly entrusted in his faith they shall ever be true children of God and worthy scholars of St. Francis School and shall always be sensitive to the feelings of others especially the poor and needy. The School has the proud privilege that over the years it has acquired a reputation of it’s own and the alumni of the School have excelled in various streams of creative pursuits and have been placed in formidable positions within and outside the Country. In fact it’s their success and authority throughout the world that provokes and motivates their younger generations to even outperform them.</p>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Objective

